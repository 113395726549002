import React, {useEffect, useState} from 'react'

import './moi.scss'
import {getData} from '../../Data/DataTravaux'

import proj from '../../image/pen-tool.svg'
import exp from '../../image/coffee.svg'
import ageicon from '../../image/clock.svg'
import bac from '../../image/flag.svg'
import Passion from "../11_Passion/Passion";
import Competence from "../12_Competence/Competence";
import BandeauFin from "../16_BandeauFin/BandeauFin";
import Pastille from "../Utils/Pastille/Pastille";
import Travaux from "../14_Travaux/Travaux";
import Parcours from "../13_Parcours/Parcours";
import avatar from '../../image/avatar-svgrepo-com.svg'
import Time from "../10_Time/Time";
import bg from "../../image/bg.svg";
import Contact from "../15_Contact/Contact";

export default function Moi() {

    const [age, setAge] = useState<number>(0)

    useEffect(() => {
        getAge()
    }, [])

    /**
     * Fonction qui a pour but de donner mon age
     */
    const getAge = () => {
        const currentDate = new Date()
        const anneeNaissance = 2002
        let currentAge = currentDate.getFullYear() - anneeNaissance
        if (currentDate.getMonth() < 11 || (currentDate.getMonth() === 11 && currentDate.getDate() <= 27)) {
            currentAge--
        }
        setAge(currentAge)
    }

    return (age === undefined ? "" :
            <div className={"container_moi"}>
                <Pastille text={"Moi"} id={"moi"}/>
                <div className={"aPropos"}>
                    <h1 className={"titre"}>Qui suis-je ?</h1>
                    <div className={"separator"}/>

                    <div className={"card"}>
                        <div>
                            <img src={avatar as string} alt="avatar"/>
                            <h1>Corentin DEPRECQ</h1>
                            <p>Développeur web</p>
                        </div>

                        <div className={"buble buble1"}/>
                        <div className={"buble buble2"}/>
                        <div className={"buble buble3"}/>
                        <div className={"buble buble4"}/>
                    </div>

                    <a rel={"noreferrer"} className={"cv"} target={"_blank"}
                       href="https://corentin.deprecq1.gitlab.io/docs/CV.pdf">Mon
                        CV</a>

                    <p className={"text blur_item"}>Je m'appelle <span
                        style={{fontWeight: "bolder", fontStyle: "italic"}}>Corentin DEPRECQ</span>,
                        j'ai actuellement {age} ans et je suis étudiant à <span style={{fontWeight: "bolder"}}>Epitech TOULOUSE</span>.
                        Ce que j'aime le plus dans l'informatique, c'est
                        le <u>web</u> et l'aspect du <u>design</u>. Passionné dans ces domaines, j'ai pu acquérir de
                        nombreuses compétences
                        et des connaissances. J'ai la chance de pouvoir faire mes périodes en milieu professionnel au
                        sein de <b>l'équipe développement de SPHEREA TOULOUSE</b> !
                    </p>
                    <div className={"chiffres"}>
                        <div className={"chiffre"}>
                            <div>
                                <img src={proj as string} alt="projet"/>
                            </div>
                            <div>
                                <p>{getData().length}</p>
                                <h2>Projets réalisés</h2>
                            </div>
                        </div>
                        <div className={"chiffre"}>
                            <div>
                                <img src={exp as string} alt="annees expe"/>
                            </div>
                            <div>
                                <p>+{age - 17}</p>
                                <h2>Années d'expériences</h2>
                            </div>
                        </div>
                        <div className={"chiffre"}>
                            <div>
                                <img src={ageicon as string} alt="age"/>
                            </div>
                            <div>
                                <p>{age}</p>
                                <h2>Age</h2>
                            </div>
                        </div>
                        <div className={"chiffre"}>
                            <div>
                                <img src={bac as string} alt="bac"/>
                            </div>
                            <div>
                                <p>4</p>
                                <h2>Niveau de bac</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <Time/>
                <Pastille text={"Mes passions"} id={"passion"}/>
                <Passion/>
                <Pastille text={"Mes Compétences"} id={"competence"}/>
                <Competence/>
                <Pastille text={"Mon Parcours"} id={"histo"}/>
                <Parcours/>
                <Pastille text={"Mes Réalisations"} id={"real"}/>
                <Travaux/>
                <Pastille text={"Contact"} id={"contact"}/>
                <Contact/>
                <BandeauFin/>
                <img className={"bg_bottom"} src={bg as string} alt="backgroud"/>
            </div>
    )
}
